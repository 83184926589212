/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";



// MDB Bootstrap5 rules
@import "@elit-it/mdb5/assets/scss/mdb.scss";
@import "@elit-it/mdb5-file-upload/scss/file-upload";
mdb-file-upload.file-upload-wrapper .file-upload { border: 1px solid #d4d4d8; }
image-cropper { height: 100%; border: 1px solid #d4d4d8; }



// Page default rules
html, body { height: 100%; }
body { margin: 0; }
*:not(mat-icon):not(i):not(svg):not(.ck-content) { font-family: "Work Sans", sans-serif; }



// CK Content 5 Editor SCSSS rules
@import "@elit-it/ckeditor/style.css";
.ck-content, .ck-content * { font-family: 'Red Hat Display', sans-serif!important; }
.ck-content figure > img { border-radius: 10px 40px 10px 10px; overflow: hidden; }
.ck-content figure:has(> figcaption) > img { border-radius: 10px 40px 0 0; overflow: hidden; }
.ck-content figure:has(> figcaption) > figcaption { border-radius: 0 0 10px 10px; overflow: hidden; }
.ck-content { > h2, > h3, > h4, > h5, > h6 { margin-top: 2rem; } }



// Angular Material alerts SCSSS rules
snack-bar-container.mat-alert {
  max-width: 400px !important;
  margin: 10px !important;
  padding: 10px 12px !important;
  opacity: .6 !important;
  transition-duration: .5s !important;
  overflow: hidden;
}
snack-bar-container.mat-alert:hover {
  opacity: 1 !important;
}
div.custom-alert.custom-alert-warning {
  border-radius: 0 0 .5rem .5rem;
  margin: 1rem 0!important;
  font-size: .8rem;
  padding: 10px 12px;
  border-top: 2px solid rgba(255, 171, 59, 1);
  background-color: #ffedd4;

  p { margin-bottom: 0 }
  span { font-weight: bold }
}
div.custom-alert.custom-alert-danger {
  border-radius: 0 0 .5rem .5rem;
  margin: 1rem 0!important;
  font-size: .8rem;
  padding: 10px 12px;
  border-top: 2px solid #ef4444;
  background-color: #fee2e2;

  p { margin-bottom: 0 }
  span { font-weight: bold }
}
div.custom-alert.custom-alert-info {
  border-radius: 0 0 .5rem .5rem;
  margin: 1rem 0!important;
  font-size: .8rem;
  padding: 10px 12px;
  border-top: 2px solid #2563eb;
  background-color: #dbeafe;

  p { margin-bottom: 0 }
  span { font-weight: bold }
}

// Angular material form element rules
mat-option.multiline {
  height: auto!important;
  padding: 8px 16px !important;

  span.mat-option-text {
    display: block;
    line-height: normal;
  }
}
mat-option.d-flex span.mat-option-text {
  display: flex!important;
  align-items: center;
}
mat-option.autocomplete span.mat-option-text {
  display: inline-block !important;
  line-height: normal;
}
mat-option.first-upper span.mat-option-text:first-letter {
  text-transform: uppercase;
}
mat-form-field { width: 100%; }
mat-form-field.wrapper-pb-0 div.mat-form-field-wrapper { padding-bottom: 0; }

// Angular Material other rules
.mat-menu-panel { min-height: auto!important; }
.mat-ink-bar { background-color: #055c66!important; }
.mat-menu-content:not(:empty) { padding-top: 0!important; padding-bottom: 0!important; }



// Other shared rules
.spinner-border { vertical-align: middle; border-color: #055c66; border-right-color: transparent; }
.subinfo { color: #757575; font-size: .75rem; line-height: normal; }
.grecaptcha-badge { visibility: hidden; }
.rsr-radius { border-radius: 10px 40px 10px 10px; overflow: hidden; }
.rsr-bullet::before { content: '\2022'; font-weight: 900; padding: 0 .25rem; }



// Calendar styles
@import "angular-calendar/css/angular-calendar.css";
